var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-main"},[_c('ebsn-style',{attrs:{"target":_vm.category,"path":"category_advanced.CSS_STYLE"}}),_c('category-block',{staticClass:"category-block category-block-1",attrs:{"target":_vm.category,"category-id":_vm.category.categoryId,"position":"position1"}}),_c('div',{staticClass:"category-header"},[(
        !_vm.$ebsn.meta(_vm.category, 'category_info.OVERLAYED_HEADER_IMAGE', true)
      )?_c('CategoryTitle',{attrs:{"category":_vm.category,"count":_vm.count,"selectedCategory":_vm.selectedCategory}}):[_c('div',{staticClass:"category-title"},[_c('v-img',{attrs:{"src":_vm.$ebsn.meta(_vm.category, 'category_info.HEADER_IMAGE'),"cover":"","onerror":"this.onerror=null;this.src='/no-icon.png'","height":"170px"}},[_c('ebsn-meta',{staticClass:"pt-16 text-center main-category-title",style:({ color: _vm.$ebsn.meta(_vm.category, 'category_info.COLOR') }),attrs:{"target":_vm.category,"path":"category_info.TITLE","default-value":_vm.category.name,"tag":"h1"}})],1)],1)]],2),_c('v-container',[_c('Breadcrumb',{attrs:{"items":_vm.breadcrumb}}),[_c('div',{staticClass:"products"},[_c('v-container',[_c('v-row',{staticClass:"mb-8"},_vm._l((_vm.category.children),function(subCategory){return _c('v-col',{key:subCategory.id,attrs:{"cols":"6","sm":"6","lg":"3"}},[_c('v-card',{staticClass:"proposal-card d-flex flex-column justify-space-around align-center",attrs:{"rounded":"sm","flat":"","to":{
                  name: 'Category',
                  params: { pathMatch: subCategory.slug }
                }}},[_c('v-img',{attrs:{"src":subCategory.metaData.category_info.ICON
                      ? subCategory.metaData.category_info.ICON
                      : '/no-image-grey.png',"alt":subCategory.name,"width":"170","height":"170"}}),_c('v-card-text',{staticClass:"pre-slide-text align-center",domProps:{"innerHTML":_vm._s(subCategory.name)}})],1)],1)}),1)],1),_c('div',{staticClass:"secondary product-slider-wrapper"},[_c('ProductListSlider',{staticClass:"secondary",attrs:{"layout":25,"categoryId":_vm.category.categoryId,"promo":true,"title":'In promozione in ' + _vm.category.name,"paginationClass":'swiper-pagination-promo',"position":"category_main_i_piu_venduti"}})],1),_c('div',{staticClass:"grey lighten-2 product-slider-wrapper"},[_c('ProductListSlider',{attrs:{"layout":27,"categoryId":_vm.category.categoryId,"title":'I più venduti in ' + _vm.category.name,"paginationClass":'swiper-pagination-venduti',"position":"category_main_in_promozione"}})],1)],1)]],2),_c('category-block',{staticClass:"category-block category-block-2",attrs:{"target":_vm.category,"category-id":_vm.category.categoryId,"position":"position2"}}),_c('category-block',{staticClass:"category-block category-block-3",attrs:{"target":_vm.category,"category-id":_vm.category.categoryId,"position":"position3"}}),_c('v-container',[_c('ebsn-meta',{attrs:{"target":_vm.category,"path":"category_info.FOOTER_DESCRIPTION","tag":"div"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }